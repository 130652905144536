* {
  font-family: "Poppins", sans-serif;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.fade {
  opacity: 1 !important;
}

.modal-backdrop.fade {
  opacity: 0 !important;
}

.modal-backdrop.show {
  opacity: 0.5 !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.ngx-datatable.bootstrap .datatable-footer {
  background: #5b50ff !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  font-weight: 600;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #6068fc !important;
  font-weight: bold;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #ededed;
  background-color: #6983c0;
}

@media(max-width:991px) {
  datatable-scroller.datatable-scroll.ng-star-inserted {
      width: 100% !important;
  }
  datatable-body.datatable-body {
      width: 100% !important;
  }
  datatable-scroller.datatable-scroll.ng-star-inserted {
      width: 100% !important;
  }
  datatable-header.datatable-header.ng-star-inserted {
      width: 100% !important;
  }
}

.export-div {
  font-weight: 600;
}

.body-section {
  background: #fff;
  padding: 13px 20px;
  border-radius: 4px;
}

.header {
  background: #5b50ff;
  padding: 0 15px;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.user-prof {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
}

.user-prof img.user {
  margin: 0 8px 0 0;
}

.angle-dwn {
  margin: 0 0 0 5px;
}

.navbar-inverse {
  background-color: transparent;
  border-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  color: #000;
  background-color: transparent;
}

.menu li a img {
  margin: 0 8px 0 0;
}

.main-container {
  background: #f2f2f2;
  padding: 20px 0;
}

.form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.breadcrum {
  color: #000;
  font-weight: 600;
}

.breadcrum span {
  color: #5b50ff;
}

.common-bttns.space-bttn a {
  padding: 10px;
  border: solid 1px #5b50ff;
  border-radius: 25px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  margin: 0 0 0 12px;
  width: 100%;
}

.common-bttns.space-bttn a.save {
  background: #5b50ff;
  color: #fff;
  cursor: pointer;
}

.common-bttns.space-bttn a:hover {
  text-decoration: none;
  box-shadow: 0 2px 3px #a2c1fc !important;
}

.row-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 40px 0;
  flex-wrap: wrap;
}

.form-inp {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 0 31%;
  max-width: 31%;
  padding: 0 10px;
}

.form-inp label {
  margin: 0 0 9px 0;
  font-size: 14px;
  font-weight: normal;
  color: #333;
}

.form-row h3 {
  font-size: 17px;
  font-weight: 600;
  margin: 0 0 23px 0;
}

.form-section {
  background: #fff;
  padding: 20px;
}

.form-inp input,
.form-inp select {
  width: 100%;
  display: inline-block;
  background-color: #f2f2f2;
  border: none;
  padding: 9px;
}

select {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  background-image: url('select-drop.png');
  background-repeat: no-repeat;
  background-position: 96% 18px;
}

.common-bttns button {
  border-radius: 25px;
  padding: 10px;
  max-width: 150px;
  width: 100%;
  background: rgba(91, 80, 255, 0.2);
  border: none;
  color: #0d00cf;
  margin: 0 0 40px 0;
}

.common-bttns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.common-bttns button:hover,
.search-sec button:hover {
  background: #0d00cf;
  color: #fff;
}

.search-sec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-sec button {
  border-radius: 25px;
  border: solid 1px #0d00cf;
  padding: 8px 10px;
  background: transparent;
  color: #0d00cf;
  min-width: 100px;
  width: 100%;
}

.search-tab input {
  background: transparent;
  border: none;
}

.search-tab {
  border-radius: 25px;
  border: solid 1px #d2d2d2;
  padding: 8px 10px;
  margin: 0 20px 0 0;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  width: 75%;
}

.data-table td img {
  margin: -3px 6px 0 5px;
}

.data-table td,
.data-table th {
  padding: 12px !important;
}

.data-table th {
  background: #f2f2f2;
}

.edit {
  color: #5b50ff;
}

.delete {
  color: #ee5a5a;
}

td.actions a {
  margin: 0 10px 0 0px;
}

.form-section .form-title {
  margin: -5px -20px 20px;
  padding: 0 20px 15px;
  border-bottom: solid 1px #d2d2d2;
}

.row-two-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row-two-col .form-inp {
  max-width: 50%;
  width: 100%;
  flex: 0 0 50%;
  margin: 0 0 25px 0;
}

.login-container {
  display: flex;
  align-items: center;
  background: #f8f8ff;
  height: 100vh;
}

.login-left {
  max-width: 50%;
  flex: 0 0 50%;
  height: 100vh;
  overflow: hidden;
}

.login-right {
  max-width: 50%;
  flex: 0 0 50%;
  text-align: center;
}

.login-left img {
  width: 100%;
  height: 100%;
}

.login-form {
  background: #fff;
  max-width: 550px;
  margin: 40px auto 0;
  padding: 25px;
  text-align: left;
  border-radius: 5px;
}

.login-field {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  border-bottom: solid 1px #d2d2d2;
  padding: 0 0 5px 0;
  flex-direction: column;
}

.login-field input {
  width: 100%;
  margin: 0 0 0 23px;
  padding: 5px;
  border: none;
  color: #000;
}

.login-field label {
  color: #999;
  font-size: 11px;
}

.login-field img {
  width: 20px;
  height: auto;
  margin: 0 8px 0 0px;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-button button {
  border-radius: 25px;
  max-width: 120px;
  width: 100%;
  text-align: center;
  background: #5b50ff;
  color: #fff;
  border: none;
  padding: 10px;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.login-button button:hover {
  box-shadow: 0 2px 3px #a2c1fc !important;
}

.login-button button img {
  margin: -5px 0 0 12px;
}

.form-section .common-bttns a {
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
}

.school-radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radio-sel:first-of-type {
  margin: 0 20px 0 0px;
}

.radio-sel label {
  margin: 0 0 0 6px;
  font-size: 14px;
  color: #4d4d4d;
}

*,
*:focus {
  outline: none;
}

.sidebar-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 20px;
}

.sidebar-page {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #fbfbff;
}

.open-school .form-title {
  background: #fff;
  padding: 13px 20px;
  border-radius: 4px;
}

.school-sidebar {
  width: 100%;
  background: #fff;
}

.sidebar-filter h3 {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
}

.sidebar-filter span img {
  margin: -3px 2px 0 0;
}

.school-sidebar-content {
  padding: 20px 35px;
  background: #fbfbff;
}

.school-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
  border-bottom: solid 1px #d2d2d2;
  padding: 0 0 10px 0;
}

.school-title h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #25265e;
}

.map-bttns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 0 0;
}

.map-bttns a {
  padding: 0 0 0 20px;
}

.sidebar-list ul {
  padding: 0;
}

.sidebar-list ul li {
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: solid 1px #d2d2d2;
}

.list-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.school-sidebar {
  flex: 0 0 30%;
  max-width: 30%;
}

.school-map img {
  width: 100%;
}

.sidebar-list ul li h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.sidebar-list ul li p {
  margin: 0;
  font-size: 12px;
}

.list-img img {
  width: 32px;
  margin: 0 0 0 10px;
}

li.active-list {
  background: #5b50ff;
  color: #fff;
  position: relative;
}

li.active-list:before {
  position: absolute;
  background: url('list-triangle.png');
  background-position: 100% 0;
  content: "";
  width: 19px;
  height: 19px;
  right: -8px;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}

.navbar {
  margin-bottom: 0;
}

.school-title .action a {
  margin: 0 0 0 15px;
}

.map-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.school-sidebar-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.activist-sec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.executive-content {
  margin-left: 20px;
}

.executive-num {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.visit-information-section {
  /* background: #F5F5FF; */
  /* padding: 20px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.visit-info-sidebar {
  flex: 0 0 35%;
  background: #fff;
  padding: 20px;
}

.lead-sidebar {
  flex: 0 0 35%;
  background: #fff;
  padding: 0;
}

.lead-sidebar li.active-list {
  background: #f5f5ff;
  color: #333;
  position: relative;
}

.lead-sidebar li:first-child {
  border: none;
}

.lead-sidebar li.active-list:before {
  position: absolute;
  background: #6291f9;
  background-position: 100% 0;
  content: "";
  width: 5px;
  height: 100%;
  right: auto;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  left: 0;
}

.lead-sidebar ul li h3 {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.lead-sidebar .list-img img {
  width: 17px;
  margin: 0 0 0 10px;
}

.visit-info-content {
  flex: 0 0 65%;
  background: #f5f5ff;
  padding: 20px;
}

.visit-information-section h3 {
  flex: 0 0 100%;
  font-size: 16px;
  font-weight: 600;
  margin: 35px 0 15px 0;
}

.info-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-bottom: solid 1px #d2d2d2;
}

.info-row span {
  font-size: 13px;
}

.info-row span:first-of-type {
  max-width: 140px;
  margin: 0 20px 0 0;
  width: 100%;
}

.info-row span:first-of-type img {
  margin: 0 5px 0 0;
  width: auto;
  height: 25px;
}

.info-row span:nth-child(2n) {
  text-align: right;
}

.info-block {
  margin: 0 0 0 0;
  opacity: 0.5;
  position: relative;
  padding: 0 0 40px 20px;
}

.info-block:before {
  left: 0;
  width: 8px;
  height: 8px;
  background: #868383;
  position: absolute;
  content: "";
  top: 7px;
  border-radius: 50%;
}

.info-block.active-info {
  opacity: 1;
  position: relative;
}

.info-block.active-info:before {
  background: #6d6fde;
  width: 15px;
  height: 15px;
  left: -4px;
  top: 5px;
}

.info-block:after {
  left: 3px;
  width: 2px;
  height: 100%;
  background: #868383;
  top: 0;
  content: "";
  position: absolute;
}

.info-block span {
  margin: 0 0 10px 0;
  display: inline-block;
  font-size: 13px;
  color: #061058;
  position: relative;
  width: 100%;
}

.info-block.active-info span:before {
  right: -31px;
  width: 20px;
  height: 20px;
  background: url('active-comment.png');
  top: 0;
  content: "";
  position: absolute;
}

.hot-txt {
  color: #ff0000;
  background: rgba(249, 96, 96, 0.1);
  border-radius: 20px;
  padding: 5px 20px;
}

.intrest-txt {
  color: #fd9000;
  background: rgba(249, 96, 96, 0.1);
  border-radius: 20px;
  padding: 5px 20px;
}

span.hot-txt img {
  width: 14px;
  margin: -3px 4px 0 0;
}

.comment-info {
  display: flex;
  flex-direction: column;
}

.comment-info textarea {
  border: solid 1px #d2d2d2;
  height: 120px;
  resize: none;
  padding: 15px;
}

.comment-send {
  background: #001f61;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-send a {
  color: #fff;
}

.map-status {
  display: flex;
  align-items: center;
}

.map-per-name {
  margin: 0 0 0 13px;
}

.map-per-name h3 {
  margin: 0 0 3px 0;
  font-size: 20px;
}

.map-per-name p {
  margin: 0 0 0 0;
  font-size: 14px;
}

.map-status .intrest-txt {
  margin-left: 20px;
}

.attendance-section {
  margin: 0 0 0 0;
}

.attendance-section img {
  width: 45px;
}

.executive-content h3 {
  font-size: 18px;
  margin: 0 0 5px 0;
  font-weight: 600;
}

.num-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.num-content h2 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
}

.num-content p {
  margin: 0;
  font-size: 12px;
  color: #72738f;
}

.num-img {
  margin: 0 8px 0 0;
}

.dashboard-map {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.dashboard-map h3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px !important;
}

.dashboard-map-lft img,
.dashboard-map-rht img {
  max-width: 100%;
}

.dashboard-map-lft {
  margin-right: 10px;
  box-shadow: 0 0 10px 2px #ddd;
  border-radius: 5px;
  overflow: hidden;
  width: 33%;
  max-width: 100%;

  flex: 0 0 49%;
}

.dashboard-map-rht {
  margin-left: 10px;
  box-shadow: 0 0 10px 2px #ddd;
  border-radius: 5px;
  overflow: hidden;
  width: 68%;
  max-width: 100%;

  flex: 0 0 49%;
}

.marketer-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 7px;
}

.marketer-lft {
  background: #fff;
  margin: 20px 0 0 0;
  border-radius: 4px;
  width: 68%;
  max-width: 100%;
  margin-right: 10px;
  box-shadow: 0 0 10px 2px #ddd;

  flex: 0 0 49%;
}

.marketer-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 370px;
  overflow-y: scroll;
}

.marketer-rht {
  background: #fff;
  margin: 20px 0 0 0;
  border-radius: 4px;
  width: 33%;
  max-width: 100%;
  margin-left: 10px;
  box-shadow: 0 0 10px 2px #ddd;

  flex: 0 0 49%;
}

.marketer-rht img {
  width: 100%;
}

.single-mrkt-list {
  max-width: 100%;
  flex: 0 0 100%;
  background: #f9f9f9;
  padding: 10px;
  margin: 0 0px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.single-mrkt-list span > img {
  width: 47px;
  height: 40px;
  margin: 0 12px 0 0;
  display: block;
  border-radius: 50%;
}

.single-mrkt-list span ul img {
  width: 40px;
  height: 40px;
  margin: 0 auto 7px;
  display: block;
}

.single-mrkt-list span {
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.single-mrkt-list span ul {
  padding: 0;
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: space-between;
}

.single-mrkt-list span ul li {
  list-style: none;
  padding: 0 7px 3px;
  text-align: center;
}

.marketer-title {
  margin: 0 0 15px 0;
  font-weight: 600;
  font-size: 16px;
  display: flex !important;
  border-bottom: solid 1px #d2d2d2;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.productSales select {
  margin-left: 10px;
  padding: 0px 20px 0px 10px;
  background-position: 96% 9px;
}

.chart-head {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.custom-filter {
  padding: 15px;
  max-width: 223px;
  width: 100%;
  min-width: 223px;
}

.custom-filter .dropdown-divider + label,
.custom-filter .dropdown-divider + .form-group > label {
  border-bottom: solid 1px #d2d2d2;
  width: 100%;
  margin: 0 0 15px 0;
  padding-bottom: 6px;
}

.form-check input {
  margin-right: 8px;
}

.lead-head {
  flex: 0 0 100%;
  max-width: 35%;
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logout-drop {
  min-width: 100px !important;
  padding: 0;
}

.logout-drop a {
  width: 100%;
  display: inline-block;
  padding: 10px;
}

.lead-head ul li h2 {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.lead-head select {
  padding: 10px 17px;
  background-color: #eef4fd;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  /* max-width: 155px; */
  width: 232px;
  font-weight: 600;
  background-position: 89% 17px;
}

.lead-head h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 0;
}

.lead-cal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lead-cal select {
  margin: 0 0 0 10px;
}

.calendar-main {
  background: #fff;
  margin: 25px 0 0 0;
  border-radius: 15px;
}

.visit-info-content .info-row select {
  float: right;
  padding: 10px 30px 10px 20px;
  border-radius: 22px;
  border: solid 1px #d2d2d2;
  background-position: 87% 17px;
}

.no-data img {
  width: 100%;
}

.executive-pic {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  overflow: hidden;
}

.executive-pic img {
  width: 100%;
}

.error-page {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error-page img {
  width: 115px;
  height: 103px;
}

.error-page h1 {
  font-size: 7em;
  font-weight: 600;
  color: #000;
}

.error-page h3 {
  font-size: 2em;
  font-weight: 600;
  color: #000;
}

.error-page a {
  font-size: 18px;
  font-weight: 600;
}

.error-msg {
  color: red;
}

.dropdown-menu.show[_ngcontent-gsk-c56] {
  transform: translate3d(-103px, 24px, 0px) !important;
  -webkit-transform: translate3d(-103px, 24px, 0px) !important;
}

.mrkt-title {
  font-size: 15px;
  margin-bottom: 10px;
}

.market-list-icons {
  width: 100%;
}

.productSalesOverview select {
  /* margin: 10px; */
  padding: 5px 20px 7px 10px;
  background-position: 88% 11px;
}

.dashboard-map-lft .h3,
.dashboard-map-rht h3 {
  color: darkblue;
  margin: 2px 0px 0px 0px;
  display: inline-block;
  padding: 10px 20px;
}

.attendance-section a {
  text-align: center;
}

.user-prof button {
  background: transparent;
  border: none;
}

.user-prof button:hover,
.user-prof button:focus {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.chartjs-render-monitor {
  width: 100% !important;
}

@media screen and (min-width: 1400px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .sidebar-page {
    flex-direction: column;
  }
  .school-sidebar {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .school-sidebar-content {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .single-mrkt-list span ul {
    flex-wrap: wrap;
  }

  .single-mrkt-list span ul li {
    margin: 0 0 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .single-mrkt-list {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .marketer-section {
    flex-wrap: wrap;
  }
  .marketer-lft {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  .marketer-rht {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  .dashboard-map-lft {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    padding: 20px;
    height: 355px;
  }
  .dashboard-map {
    flex-wrap: wrap;
  }
  .dashboard-map-rht {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    height: 355px;
  }
  .open-school .form-title {
    flex-wrap: wrap;
  }
  .sidebar-filter {
    flex-wrap: wrap;
    padding: 20px 10px;
  }

  .sidebar-filter h3 {
    font-size: 14px;
  }
  .sidebar-list ul li {
    flex-wrap: wrap;
  }
  .list-img img {
    margin: 10px 10px 0 0;
  }
  .search-sec {
    flex-wrap: wrap;
    margin: 15px 0 15px 0;
  }
  .single-mrkt-list span ul {
    flex-wrap: wrap;
  }

  .single-mrkt-list span ul li {
    padding: 0 30px 15px 0;
  }
}

@media screen and (max-width: 666px) {
  .form-inp {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 15px;
  }

  .row-two-col .form-inp {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }

  .dashboard-map-lft img,
  .dashboard-map-rht img {
    max-width: 100%;
    width: 100%;
  }
  .login-container {
    flex-wrap: wrap;
    height: auto;
  }

  .login-left,
  .login-right {
    max-width: 100%;
    flex: 0 0 100%;
    height: auto;
  }
  .login-left {
    order: 2;
  }

  .activist-sec {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .executive-content {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
  .executive-num {
    margin: 25px 0;
  }
  .school-title h3 {
    font-size: 14px;
  }
  .school-sidebar-content {
    padding: 20px 15px;
  }
}

.ng-select.ng-select-opened>.ng-select-container{background:#fff;border-color:#b3b3b3 #ccc #d9d9d9}

.ng-select.ng-select-opened>.ng-select-container:hover{box-shadow:none}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow{top:-2px;border-color:transparent transparent #999;border-width:0 5px 5px}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{border-color:transparent transparent #333}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{border-bottom-right-radius:0;border-bottom-left-radius:0}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container{border-top-right-radius:0;border-top-left-radius:0}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#007eff;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)}

.ng-select.ng-select-disabled>.ng-select-container{background-color:#f9f9f9}

.ng-select .ng-has-value .ng-placeholder{display:none}

.ng-select .ng-select-container{color:#333;background-color:#fff;border-radius:4px;border:1px solid #ccc;min-height:36px;align-items:center}

.ng-select .ng-select-container:hover{box-shadow:0 1px 0 rgba(0,0,0,0.06)}

.ng-select .ng-select-container .ng-value-container{align-items:center;padding-left:10px}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container{padding-right:10px;padding-left:0}

.ng-select .ng-select-container .ng-value-container .ng-placeholder{color:#999}

.ng-select.ng-select-single .ng-select-container{height:36px}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:5px;left:0;padding-left:10px;padding-right:50px}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-right:10px;padding-left:50px}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{background-color:#f9f9f9;border:1px solid #e6e6e6}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{padding:0 5px}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-top:5px;padding-left:7px}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-right:7px;padding-left:0}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{font-size:.9em;margin-bottom:5px;background-color:#ebf5ff;border-radius:2px;margin-right:5px}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin-right:0;margin-left:5px}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:#f9f9f9}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:5px}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:0;padding-right:5px}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{display:inline-block;padding:1px 5px}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{display:inline-block;padding:1px 5px}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{background-color:#d1e8ff}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-right:1px solid #b8dbff}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-left:1px solid #b8dbff;border-right:none}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:1px solid #b8dbff}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:0;border-right:1px solid #b8dbff}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 0 3px 3px}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 3px 3px 0}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{top:5px;padding-bottom:5px;padding-left:3px}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{padding-right:3px;padding-left:0}

.ng-select .ng-clear-wrapper{color:#999}

.ng-select .ng-clear-wrapper:hover .ng-clear{color:#D0021B}

.ng-select .ng-spinner-zone{padding:5px 5px 0 0}

[dir="rtl"] .ng-select .ng-spinner-zone{padding:5px 0 0 5px}

.ng-select .ng-arrow-wrapper{width:25px;padding-right:5px}

[dir="rtl"] .ng-select .ng-arrow-wrapper{padding-left:5px;padding-right:0}

.ng-select .ng-arrow-wrapper:hover .ng-arrow{border-top-color:#666}

.ng-select .ng-arrow-wrapper .ng-arrow{border-color:#999 transparent transparent;border-style:solid;border-width:5px 5px 2.5px}

.ng-dropdown-panel{background-color:#fff;border:1px solid #ccc;box-shadow:0 1px 0 rgba(0,0,0,0.06);left:0}

.ng-dropdown-panel.ng-select-bottom{top:100%;border-bottom-right-radius:4px;border-bottom-left-radius:4px;border-top-color:#e6e6e6;margin-top:-1px}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{border-bottom-right-radius:4px;border-bottom-left-radius:4px}

.ng-dropdown-panel.ng-select-top{bottom:100%;border-top-right-radius:4px;border-top-left-radius:4px;border-bottom-color:#e6e6e6;margin-bottom:-1px}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{border-top-right-radius:4px;border-top-left-radius:4px}

.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid #ccc;padding:5px 7px}

.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid #ccc;padding:5px 7px}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;user-select:none;padding:8px 10px;font-weight:500;color:rgba(0,0,0,0.54);cursor:pointer}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background-color:#f5faff}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{background-color:#ebf5ff;font-weight:600}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{background-color:#fff;color:rgba(0,0,0,0.87);padding:8px 10px}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{color:#333;background-color:#ebf5ff}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{font-weight:600}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color:#f5faff;color:#333}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:#ccc}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:22px}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:22px;padding-left:0}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{font-size:80%;font-weight:400;padding-right:5px}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}

[dir="rtl"] .ng-dropdown-panel{direction:rtl;text-align:right}

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media screen and (min-width: 1400px) {
    .container {
        max-width: 90%;
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  color: #000 !important;
  background-color: transparent !important;
}

.navbar-inverse {
    background-color: transparent !important;
    border-color: transparent !important;
}



.navbar-nav {
    display: -webkit-box !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-inverse .navbar-nav > li {
    position: relative;
    display: block;
}  */

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

.nav .navbar-nav {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    display: block;
}

.navbar-nav {
    flex-direction: row !important;
}

.navbar {
    padding: 0%;
}

.nav > li {
    position: relative;
    display: block;
  }

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
  }

.nav > li > a:hover,
  .nav > li > a:focus {
    text-decoration: none;
    background-color: #eee;
  }

.nav > li.disabled > a {
    color: #777;
  }

.nav > li.disabled > a:hover,
  .nav > li.disabled > a:focus {
    color: #777;
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
  }

.nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: #eee;
    border-color: #337ab7;
  }

.nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }

.nav > li > a > img {
    max-width: none;
  }

.nav-tabs {
    border-bottom: 1px solid #ddd;
  }

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
  }

.nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
  }

.nav-tabs > li > a:hover {
    border-color: #eee #eee #ddd;
  }

.nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
  }

.nav-tabs.nav-justified {
    width: 100%;
    border-bottom: 0;
  }

.nav-tabs.nav-justified > li {
    float: none;
  }

.nav-tabs.nav-justified > li > a {
    margin-bottom: 5px;
    text-align: center;
  }

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%;
    }
    .nav-tabs.nav-justified > li > a {
      margin-bottom: 0;
    }
  }

.nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px;
  }

.nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd;
  }

@media (min-width: 768px) {
    .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
    }
    .nav-tabs.nav-justified > .active > a,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #fff;
    }
  }

.nav-pills > li {
    float: left;
  }

.nav-pills > li > a {
    border-radius: 4px;
  }

.nav-pills > li + li {
    margin-left: 2px;
  }

.nav-pills > li.active > a,
  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #337ab7;
  }

.nav-stacked > li {
    float: none;
  }

.nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0;
  }

.nav-justified {
    width: 100%;
  }

.nav-justified > li {
    float: none;
  }

.nav-justified > li > a {
    margin-bottom: 5px;
    text-align: center;
  }

.nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

@media (min-width: 768px) {
    .nav-justified > li {
      display: table-cell;
      width: 1%;
    }
    .nav-justified > li > a {
      margin-bottom: 0;
    }
  }

.nav-tabs-justified {
    border-bottom: 0;
  }

.nav-tabs-justified > li > a {
    margin-right: 0;
    border-radius: 4px;
  }

.nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border: 1px solid #ddd;
  }

@media (min-width: 768px) {
    .nav-tabs-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
    }
    .nav-tabs-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus {
      border-bottom-color: #fff;
    }
  }

.tab-content > .tab-pane {
    display: none;
  }

.tab-content > .active {
    display: block;
  }

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

.navbar {
    position: relative;
    min-height: 50px;
    /* margin-bottom: 20px; */
    border: 1px solid transparent;
  }

@media (min-width: 768px) {
    .navbar {
      border-radius: 4px;
    }
  }

@media (min-width: 768px) {
    .navbar-header {
      float: left;
    }
  }

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  }

.navbar-collapse.in {
    overflow-y: auto;
  }

@media (min-width: 768px) {
    .navbar-collapse {
      width: auto;
      border-top: 0;
      box-shadow: none;
    }
    .navbar-collapse.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
    }
    .navbar-collapse.in {
      overflow-y: visible;
    }
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }
  }

.navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
  }

@media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      max-height: 200px;
    }
  }

.container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }

@media (min-width: 768px) {
    .container > .navbar-header,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }
  }

.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
  }

@media (min-width: 768px) {
    .navbar-static-top {
      border-radius: 0;
    }
  }

.navbar-fixed-top,
  .navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }

@media (min-width: 768px) {
    .navbar-fixed-top,
    .navbar-fixed-bottom {
      border-radius: 0;
    }
  }

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
  }

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
  }

.navbar-brand:hover,
  .navbar-brand:focus {
    text-decoration: none;
  }

.navbar-brand > img {
    display: block;
  }

@media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
      margin-left: -15px;
    }
  }

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

.navbar-toggle:focus {
    outline: 0;
  }

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }

.navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px;
  }

@media (min-width: 768px) {
    .navbar-toggle {
      display: none;
    }
  }

.navbar-nav {
    margin: 7.5px -15px;
  }

.navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px;
    }
    .navbar-nav .open .dropdown-menu > li > a {
      line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus {
      background-image: none;
    }
  }

@media (min-width: 768px) {
    .navbar-nav {
      float: left;
      margin: 0;
    }
    .navbar-nav > li {
      float: left;
    }
    .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

.navbar-form {
    padding: 10px 15px;
    margin-top: 8px;
    margin-right: -15px;
    margin-bottom: 8px;
    margin-left: -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
  }

@media (min-width: 768px) {
    .navbar-form .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .navbar-form .form-control-static {
      display: inline-block;
    }
    .navbar-form .input-group {
      display: inline-table;
      vertical-align: middle;
    }
    .navbar-form .input-group .input-group-addon,
    .navbar-form .input-group .input-group-btn,
    .navbar-form .input-group .form-control {
      width: auto;
    }
    .navbar-form .input-group > .form-control {
      width: 100%;
    }
    .navbar-form .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form .radio,
    .navbar-form .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form .radio label,
    .navbar-form .checkbox label {
      padding-left: 0;
    }
    .navbar-form .radio input[type="radio"],
    .navbar-form .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }
    .navbar-form .has-feedback .form-control-feedback {
      top: 0;
    }
  }

@media (max-width: 767px) {
    .navbar-form .form-group {
      margin-bottom: 5px;
    }
    .navbar-form .form-group:last-child {
      margin-bottom: 0;
    }
  }

@media (min-width: 768px) {
    .navbar-form {
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 0;
      margin-left: 0;
      border: 0;
      box-shadow: none;
    }
  }

.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px;
  }

.navbar-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }

.navbar-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px;
  }

.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px;
  }

@media (min-width: 768px) {
    .navbar-text {
      float: left;
      margin-right: 15px;
      margin-left: 15px;
    }
  }

@media (min-width: 768px) {
    .navbar-left {
      float: left !important;
    }
    .navbar-right {
      float: right !important;
      margin-right: -15px;
    }
    .navbar-right ~ .navbar-right {
      margin-right: 0;
    }
  }

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
  }

.navbar-default .navbar-brand {
    color: #777;
  }

.navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus {
    color: #5e5e5e;
    background-color: transparent;
  }

.navbar-default .navbar-text {
    color: #777;
  }

.navbar-default .navbar-nav > li > a {
    color: #777;
  }

.navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
    color: #333;
    background-color: transparent;
  }

.navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }

.navbar-default .navbar-nav > .disabled > a,
  .navbar-default .navbar-nav > .disabled > a:hover,
  .navbar-default .navbar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }

.navbar-default .navbar-toggle {
    border-color: #ddd;
  }

.navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: #ddd;
  }

.navbar-default .navbar-toggle .icon-bar {
    background-color: #888;
  }

.navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: #e7e7e7;
  }

.navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #777;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
      color: #333;
      background-color: transparent;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #555;
      background-color: #e7e7e7;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #ccc;
      background-color: transparent;
    }
  }

.navbar-default .navbar-link {
    color: #777;
  }

.navbar-default .navbar-link:hover {
    color: #333;
  }

.navbar-default .btn-link {
    color: #777;
  }

.navbar-default .btn-link:hover,
  .navbar-default .btn-link:focus {
    color: #333;
  }

.navbar-default .btn-link[disabled]:hover,
  fieldset[disabled] .navbar-default .btn-link:hover,
  .navbar-default .btn-link[disabled]:focus,
  fieldset[disabled] .navbar-default .btn-link:focus {
    color: #ccc;
  }

.navbar-inverse .navbar-brand {
    color: #9d9d9d !important;
  }

.navbar-inverse .navbar-brand:hover,
  .navbar-inverse .navbar-brand:focus {
    color: #fff;
    background-color: transparent;
  }

.navbar-inverse .navbar-text {
    color: #9d9d9d !important;
  }

.navbar-inverse .navbar-nav > li > a {
    color: #9d9d9d !important;
  }

.navbar-inverse .navbar-nav > li > a:hover,
  .navbar-inverse .navbar-nav > li > a:focus {
    color: #fff;
    background-color: transparent;
  }

.navbar-inverse .navbar-nav > .active > a,
  .navbar-inverse .navbar-nav > .active > a:hover,
  .navbar-inverse .navbar-nav > .active > a:focus {
    color: #000 !important;
    background-color: transparent !important;
  }

.navbar-inverse .navbar-nav > .disabled > a,
  .navbar-inverse .navbar-nav > .disabled > a:hover,
  .navbar-inverse .navbar-nav > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }

.navbar-inverse .navbar-toggle {
    border-color: #333;
  }

.navbar-inverse .navbar-toggle:hover,
  .navbar-inverse .navbar-toggle:focus {
    background-color: #333;
  }

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff;
  }

.navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: #101010;
  }

.navbar-inverse .navbar-nav > .open > a,
  .navbar-inverse .navbar-nav > .open > a:hover,
  .navbar-inverse .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #080808;
  }

@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
      border-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
      background-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
      color: #9d9d9d;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
      color: #fff;
      background-color: transparent;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #fff;
      background-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #444;
      background-color: transparent;
    }
  }

.navbar-inverse .navbar-link {
    color: #9d9d9d;
  }

.navbar-inverse .navbar-link:hover {
    color: #fff;
  }

.navbar-inverse .btn-link {
    color: #9d9d9d;
  }

.navbar-inverse .btn-link:hover,
  .navbar-inverse .btn-link:focus {
    color: #fff;
  }

.navbar-inverse .btn-link[disabled]:hover,
  fieldset[disabled] .navbar-inverse .btn-link:hover,
  .navbar-inverse .btn-link[disabled]:focus,
  fieldset[disabled] .navbar-inverse .btn-link:focus {
    color: #444;
  }